import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconSearch } from 'icons';
import { CheckboxService } from 'components/checkboxService';
import debounce from 'lodash/debounce';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { workplaceSelector, workplaceSlice } from '../../workplaceSlice';
import { InputSearch, ItemDataSearchProps } from 'components/ui/inputSearch';
import { changeActiveConnectData, changeActiveDataSearch } from 'components/ui/inputSearch/utils';
import { useTabsContext } from '../../context';
import { transformDataSearch } from '../../../specialist/utils';

export const Service: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const searchData = useAppSelector(workplaceSelector.search);
  const [search, setSearch] = useState<string>('');
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const [dataSearch, setDataSearch] = useState<ItemDataSearchProps[]>([]);

  const [checkbox, setCheckbox] = useState(false);

  const activeServices = useMemo(
    () => stateTabs?.workplace?.data?.services || [],
    [stateTabs?.workplace?.data?.services],
  );

  useEffect(() => {
    if (!search) return;

    const activeIds = stateTabs?.workplace?.data?.services?.map((i: Record<string, any>) => i.id);

    const data = transformDataSearch(searchData, activeIds);

    setDataSearch(data);
  }, [searchData]);

  const handledChangeDataSearch = (id?: number, item?: ItemDataSearchProps, data?: ItemDataSearchProps[]) => {
    if (id) {
      const nextData = changeActiveDataSearch(id, dataSearch);
      setDataSearch(nextData);

      if (item) {
        const nextActiveSearch = changeActiveConnectData(item, activeServices);

        setStateTabs?.((prev) => ({
          ...prev,
          workplace: {
            ...prev?.workplace,
            data: {
              ...prev?.workplace?.data,
              services: nextActiveSearch,
            },
          },
        }));
      }
    }
  };

  const showValue = (search: string) => {
    setLoading(true);
    dispatch(
      workplaceSlice.actions.searchServiceRequest({
        payload: {
          data: {
            title: search || null,
          },
          success: () => setLoading(false),
        },
      }),
    );
    if (!search) {
      setDataSearch([]);
    }
  };

  const handledDebounce = useCallback(debounce(showValue, 500), []);

  const handledOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(!!e.target.value);
    setSearch(e.target.value);
    handledDebounce(e.target.value);
  };

  const handledChangeItemService = (id?: number, item?: ItemDataSearchProps) => () => {
    if (item) {
      const nextActiveSearch = changeActiveConnectData(item, activeServices);
      setStateTabs?.((prev) => ({
        ...prev,
        workplace: {
          ...prev?.workplace,
          data: {
            ...prev?.workplace?.data,
            services: nextActiveSearch,
          },
        },
      }));
    }
  };

  return (
    <Content>
      <Left>
        <Head>
          <Title>Услуги на этом рабочем месте</Title>
          {/*<WrapperIconPlus>*/}
          {/*  <IconPlus />*/}
          {/*</WrapperIconPlus>*/}
        </Head>
        {/*<BlockCheckbox>*/}
        {/*  <CheckboxService*/}
        {/*    active={checkbox}*/}
        {/*    setActive={() => setCheckbox((prev) => !prev)}*/}
        {/*    title={'Совмещение параллельных заказов'}*/}
        {/*  />*/}
        {/*  <WrapperInfo>*/}
        {/*    <InfoPopUp>*/}
        {/*      <TextInfo>Текст подсказки</TextInfo>*/}
        {/*    </InfoPopUp>*/}
        {/*  </WrapperInfo>*/}
        {/*</BlockCheckbox>*/}
        <WrapperInput>
          <InputSearch
            onFocus={() => setLoading(true)}
            onBlur={() => setLoading(false)}
            loading={loading}
            icon={IconSearch}
            placeholder={'Найти услугу'}
            value={search}
            onChange={handledOnChangeSearch}
            data={dataSearch}
            onChangeData={handledChangeDataSearch}
          />
          {activeServices?.length ? (
            activeServices?.map((item: ItemDataSearchProps) => (
              <ActiveItemService key={item.id}>
                <CheckboxService
                  active={item.active}
                  setActive={handledChangeItemService(item.id, { ...item, active: !item.active })}
                  title={item.label}
                />
              </ActiveItemService>
            ))
          ) : (
            <TextData> Добавьте услуги используя Поиск</TextData>
          )}
        </WrapperInput>
      </Left>
    </Content>
  );
};
const TextData = styled.div`
  font-size: 14px;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
`;
const ActiveItemService = styled.div`
  padding: 10px;

  &:hover {
    background: rgba(231, 231, 231, 0.56);
  }

  &:not(:last-child) {
    border-bottom: 2px solid var(--color-grey);
  }
`;
const TextInfo = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;
const WrapperInfo = styled.div`
  margin-left: 16px;
`;
const BlockCheckbox = styled.div`
  display: flex;
  align-items: center;
`;
const Left = styled.div`
  width: 378px;
`;
const WrapperInput = styled.div`
  margin: 24px 0 24px;
`;
const WrapperIconPlus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    path {
      fill: #6471e0;
    }
  }
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;
const Content = styled.div`
  display: flex;
  padding: 24px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 8px;
  margin-bottom: 90px;
  min-width: 720px;
`;
