import React from 'react';
import styled, { css } from 'styled-components';

type SwitchProps = {
  title: string;
  active: boolean;
  setActive: () => void;
};

export const Switch: React.FC<SwitchProps> = ({ active = false, setActive, title }) => {
  return (
    <WrapperContent>
      <Content active={active} onClick={setActive} />
      <Title>{title}</Title>
    </WrapperContent>
  );
};

const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  margin-left: 16px;
`;
const WrapperContent = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div<{ active: boolean }>`
  width: 40px;
  height: 20px;
  border-radius: 24px;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background: var(--color-white);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 1.5s ease-in-out;
  }

  ${({ active }) =>
    active
      ? css`
          background: #caceef;
          &:after {
            right: 2px;
          }
        `
      : css`
          background: #e1e0e2;
          &:after {
            left: 2px;
          }
        `}
`;
