import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { authSlice } from 'pages/auth/authSlice';
import { useAppDispatch } from 'core/hooks';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

export const Layout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(authSlice.actions.getUserRequest());
  }, []);

  useEffect(() => {
    if (['/', '/schedule'].includes(pathname)) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [pathname]);

  const isAuthenticated = !!localStorage?.getItem('auth');

  const PrivateWrapper: React.FC<Record<string, any>> = ({ isAuthenticated }) => {
    return isAuthenticated ? <Outlet /> : <Navigate to='/auth' />;
  };

  return (
    <Content>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <PrivateWrapper isAuthenticated={isAuthenticated} />
      </QueryParamProvider>
    </Content>
  );
};

const Content = styled.div`
  color: #282a2f;
  font-size: 20px;
  display: flex;
  min-width: 100%;
`;
