import { all } from 'redux-saga/effects';
import { getToken } from './getToken';
import { getRegister } from './getRegistration';
import { getConfirm } from './getConfirm';
import { getLogin } from './getLogin';
import { getEdit } from './getEdit';
import { getReset } from './getReset';
import { getLogout } from './getLogout';
import { getUser } from './getUser';

export default function* authSaga() {
  yield all([getToken(), getRegister(), getConfirm(), getLogin(), getEdit(), getReset(), getLogout(), getUser()]);
}
