import React, { useEffect, useState } from 'react';
import { ModalAnt } from '../../../components/modalAnt';
import { ItemTabs, Tabs } from '../../../components/tabs';
import { DefaultButton, WhiteButton } from '../../../components/buttons';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../core/hooks';
import { clientClientsSelector, clientsSlice } from '../../../pages/clients/clientsSlice';
import { GeneralInformation } from '../../../pages/clients/table/tabsModal/generalInformation';
import { Discount } from '../../../pages/clients/table/tabsModal/discount';
import { TabsContextProvider, useTabsContext } from '../../../pages/clients/table/tabsModal/context';
import { PopUpAnt } from '../../../components/popupAnt';
import { updateData } from '../../../pages/clients/helpers';

type ModalChangeClientProps = {
  //
  updateRequestData: (data?: Record<string, any>) => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalChangeClientContent: React.FC<ModalChangeClientProps> = ({ updateRequestData, showModal, setShowModal }) => {
  const dispatch = useAppDispatch();
  const [updateDataClient, setUpdateDataClient] = useState<Record<string, any>>();
  const client = useAppSelector(clientClientsSelector);
  const [showPopUp, setShowPopUp] = useState<'save' | null>(null);
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const title = client?.id ? [client?.surname, client?.name, client?.patronymic].join(' ') : 'Данные клиента';

  const dataTabs: ItemTabs[] = [
    {
      label: 'Общая информация',
      content: <GeneralInformation />,
    },
    {
      label: 'История',
      content: <div style={{ minWidth: '980px' }}>История</div>,
    },
    {
      label: 'Файлы',
      content: <div style={{ minWidth: '980px' }}>Файлы</div>,
    },
    {
      label: 'Скидка',
      content: <Discount />,
    },
    {
      label: 'Сообщения',
      content: <div style={{ minWidth: '980px' }}>Сообщения</div>,
    },
  ];

  useEffect(() => {
    if (client) {
      setStateTabs?.((prev) => ({ ...prev, general: { ...prev?.general, data: client } }));
    }
  }, [client]);

  const handleCloseModal = () => {
    setShowPopUp(null);
    setShowModal(false);
    setStateTabs?.({});
    dispatch(clientsSlice.actions.resetClientsActive());
    updateRequestData(updateDataClient);
  };

  const handleClickSave = (exit?: boolean) => {
    //UPDATE
    if (stateTabs?.general?.onIsValid?.()) {
      dispatch(
        clientsSlice.actions.postUpdateClientRequest({
          payload: {
            data: updateData(stateTabs?.general?.data),
            success: (data?: Record<string, any>) => {
              if (exit) {
                handleCloseModal();
                updateRequestData(data);
                setUpdateDataClient(data);
              } else {
                setShowPopUp(null);
              }
            },
          },
        }),
      );
    }
  };

  const handleSave = (exit?: boolean) => () => {
    if (exit) {
      //сохранить и выйти
      handleClickSave(exit);
    } else if (stateTabs?.general?.onIsValid?.()) {
      //сохранить
      setShowPopUp('save');
    }
  };

  const contentPopUp: Record<string, any> = {
    save: {
      title: 'Сохранить изменения?',
      onClick: () => handleClickSave(),
    },
  };

  return (
    <>
      <ModalAnt key={String(showModal)} show={showModal} onClose={handleCloseModal}>
        <Title>{title}</Title>
        <Tabs data={dataTabs} />
        <Footer>
          <BLockBtn>
            <WrapperButton>
              <DefaultButton onClick={handleSave()}>Сохранить</DefaultButton>
            </WrapperButton>
            <WrapperButton>
              <WhiteButton onClick={handleSave(true)}>Сохранить и выйти</WhiteButton>
            </WrapperButton>
          </BLockBtn>
        </Footer>
      </ModalAnt>
      {showPopUp !== null && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </>
  );
};

export const ModalChangeClient: React.FC<ModalChangeClientProps> = (props) => {
  return (
    <TabsContextProvider>
      <ModalChangeClientContent {...props} />
    </TabsContextProvider>
  );
};

const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-left: 12px;
  color: var(--color-black-light);
  margin-bottom: 10px;
`;
