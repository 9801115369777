import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconFilter } from 'icons';
import { ContentPopUpList, TitleFilter } from '../style';
import { useMouseDown } from 'core/hooks';
import { changeActiveItemFilter, countActiveFilter } from './utils';

import { Checkbox } from '../checkbox';

export type FilterDataProps = {
  id: number;
  active: boolean;
  label: string;
  value: string | number;
};

type FilterScheduleProps = {
  data: FilterDataProps[];
  onChange: (data: FilterDataProps[]) => void;
};

export const FilterSchedule: React.FC<FilterScheduleProps> = ({ data, onChange }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [counts, setCounts] = useState<number>(0);

  const [showList, setShowList] = useState(false);

  useMouseDown(() => setShowList(false), listRef, inputRef);

  const handleClickSorting = () => {
    setShowList((prev) => !prev);
  };

  const handleClickItem = (item: FilterDataProps) => () => {
    setCounts(countActiveFilter(changeActiveItemFilter(data, item)));
    onChange(changeActiveItemFilter(data, item));
  };

  return (
    <Content active={showList}>
      <Header ref={inputRef} onClick={handleClickSorting}>
        <WrapperIcon>
          <IconFilter />
        </WrapperIcon>
        <TitleFilter>Фильтр</TitleFilter>
        {!!counts && <CountFilter>{counts}</CountFilter>}
      </Header>
      <ContentPopUpList ref={listRef} active={showList}>
        {data?.map((item) => (
          <Checkbox
            key={item.id}
            title={item.value.toString()}
            active={item.active}
            setActive={handleClickItem(item)}
          />
        ))}
      </ContentPopUpList>
    </Content>
  );
};

const CountFilter = styled.div`
  height: 18px;
  width: 18px;
  background: var(--color-blue);
  color: var(--color-white);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 26px;
`;
const Content = styled.div<{ active: boolean }>`
  width: 100%;
  position: relative;
  padding: 8px 10px;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
  ${({ active }) =>
    active
      ? css`
          background: #f0f3f9;
        `
      : null}
`;
