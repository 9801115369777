import { ItemRowTypes } from './types';

export const mockData: Record<number, ItemRowTypes> = {
  1: {
    id: 1,
    number: 1,
    name: 'name 1',
    require: true,
    active: true,
    parentId: null,
  },
  2: {
    id: 2,
    number: 2,
    name: 'name 2',
    require: true,
    active: true,
    parentId: null,
  },
  3: {
    id: 3,
    number: 3,
    name: 'name 3',
    require: true,
    active: true,
    parentId: null,
  },
  4: {
    id: 4,
    number: 4,
    name: 'name 4',
    require: true,
    active: true,
    parentId: null,
  },
  5: {
    id: 5,
    number: 5,
    name: 'name 5',
    require: true,
    active: true,
    parentId: null,
  },
  6: {
    id: 6,
    number: 6,
    name: 'name 6',
    require: true,
    active: true,
    parentId: null,
  },
  7: {
    id: 7,
    number: 7,
    name: 'name 7',
    require: true,
    active: true,
    parentId: 2,
  },
};
