import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrows } from '../../icons';
import { ContentPopUpList, ItemContentPopUpList, TitleFilter } from '../style';
import { useMouseDown } from '../../core/hooks';
import { Range } from '../range';

type ZoomFilterProps = {
  onChange: (data: number) => void;
  icon?: any;
  label?: string;
};

export const ZoomFilter: React.FC<ZoomFilterProps> = ({ onChange, icon = IconArrows, label = 'Сортировка' }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);

  const [showList, setShowList] = useState(false);

  useMouseDown(() => setShowList(false), listRef, inputRef);

  const handleClickSorting = () => {
    setShowList((prev) => !prev);
  };

  const handleOnChange = (value: number) => {
    //
    onChange(value);
  };

  const IconFilter = icon;
  return (
    <Content active={showList}>
      <Header ref={inputRef} onClick={handleClickSorting}>
        <WrapperIcon>
          <IconFilter />
        </WrapperIcon>
        <TitleFilter>{label}</TitleFilter>
      </Header>
      <ContentPopUpList ref={listRef} active={showList}>
        <WrapperRange>
          <Range onChange={handleOnChange} />
        </WrapperRange>
      </ContentPopUpList>
    </Content>
  );
};
const WrapperRange = styled.div`
  width: 200px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Content = styled.div<{ active: boolean }>`
  padding: 8px 10px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
  ${({ active }) =>
    active
      ? css`
          background: #f0f3f9;
        `
      : null}
`;
