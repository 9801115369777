import counterReducer from 'features/counter/counterSlice';
import { serviceSlice } from 'pages/service/serviceSlice';
import { clientsSlice } from 'pages/clients/clientsSlice';
import { specialistSlice } from 'pages/specialist/specialistSlice';
import { workplaceSlice } from 'pages/workplace/workplaceSlice';
import { kanbanSlice } from 'pages/kanban/kanbanSlice';
import { authSlice } from 'pages/auth/authSlice';
import { scheduleSlice } from '../pages/schedule/scheduleSlice';
import { recordPrioritySlice } from '../pages/recordingPriority/recordingPrioritySlice';

export const reducers = {
  recordPriority: recordPrioritySlice.reducer,
  auth: authSlice.reducer,
  schedule: scheduleSlice.reducer,
  kanban: kanbanSlice.reducer,
  workplace: workplaceSlice.reducer,
  specialist: specialistSlice.reducer,
  service: serviceSlice.reducer,
  clients: clientsSlice.reducer,
  counter: counterReducer,
};
