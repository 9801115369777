import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

export type ItemTabs = {
  label: string;
  content: React.ReactNode;
};

type TabsProps = {
  data: ItemTabs[];
  defaultActiveTab?: number;
  disableActiveTab?: number;
};

export const Tabs: React.FC<TabsProps> = ({ data = [], defaultActiveTab = 0, disableActiveTab }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [active, setActive] = useState<ItemTabs>(data[0]);

  useEffect(() => {
    if (disableActiveTab !== undefined) {
      setActiveIndex(disableActiveTab);
      setActive(data[disableActiveTab]);
    } else {
      setActiveIndex(defaultActiveTab);
      setActive(data[defaultActiveTab]);
    }
  }, [defaultActiveTab, disableActiveTab]);
  const handleClickLabel = (index: number) => () => {
    if (disableActiveTab !== undefined) return;
    setActive(data[index]);
    setActiveIndex(index);
  };

  return (
    <Content>
      <Header>
        {data.map((tab, idx) => (
          <Label key={idx} active={activeIndex === idx} onClick={handleClickLabel(idx)}>
            {tab.label}
          </Label>
        ))}
      </Header>
      <Body>{active?.content}</Body>
    </Content>
  );
};
const Body = styled.div`
  z-index: 1;
  background: var(--color-white);
  box-shadow: 0 0 12px 0 #0000001a;
`;
const Label = styled.div<{ active: boolean }>`
  white-space: nowrap;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 8px 20px;

  ${({ active }) =>
    active
      ? css`
          background: var(--color-white);
          box-shadow: rgba(0, 0, 0, 0.05) -1px -8px 19px;
          color: var(--color-black);
        `
      : css`
          color: #6d6d6d;
        `}
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  background: var(--color-white);
  width: min-content;
`;
