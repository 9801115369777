import React from 'react';
import styled from 'styled-components';

export const CodeButton: React.FC = () => {
  return (
    <Content>
      <Title>
        Код необходимо скопировать на страницы вашего сайта. Копируйте код только если полностью осознаете, что делаете
      </Title>
      <CodeText>
        {`/* button */ /* Auto layout */ display: flex; flex-direction: row; justify-content: center; align-items: center;
        padding: 10px 16px; gap: 8px; width: 102px; height: 32px; /* button blue */ background: #6471E0; border-radius:
        4px; /* Inside auto layout */ flex: none; order: 0; flex-grow: 0;`}
      </CodeText>
    </Content>
  );
};

const CodeText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  width: 100px;
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
  margin-bottom: 24px;
`;

const Content = styled.div`
  padding: 40px 60px;
`;
