import React from 'react';
import styled from 'styled-components';
import { HeaderTable } from './header';
import { header } from './heplers';
import { Row } from './row';
import { ItemTable } from './types';

const GRID_TABLE = '.06fr .22fr .2fr .12fr .07fr .04fr .16fr .15fr ';

type TableProps = {
  handleChangeRow: (id?: number, remove?: boolean) => void;
  data: ItemTable[][];
};
export const Table: React.FC<TableProps> = ({ data, handleChangeRow }) => {
  return (
    <Content>
      <HeaderTable grid={GRID_TABLE} data={header} />
      {data?.map((row, idx) => (
        <Row key={row?.[idx]?.id?.toString()} grid={GRID_TABLE} data={row} handleChangeRow={handleChangeRow} />
      ))}
    </Content>
  );
};

const Content = styled.div``;
