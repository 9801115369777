import React from 'react';
import styled from 'styled-components';
import config from 'api/config';
import { WorkplaceProps } from './types';

type ItemWorkplaceProps = {
  data: WorkplaceProps;
  onClick: () => void;
};

export const ItemWorkplace: React.FC<ItemWorkplaceProps> = (p) => {
  const { name } = p.data;
  const url = p?.data?.image ? `${config.baseUrlImg}${p?.data?.image?.file_path}` : '#';

  return (
    <Content onClick={p.onClick}>
      <Avatar src={url} />
      <WrapperInfo>
        <Name>{name}</Name>
      </WrapperInfo>
    </Content>
  );
};
const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  object-fit: cover;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 155px;
`;
const WrapperInfo = styled.div`
  padding-left: 10px;
`;
const WrapperAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background: #d1d4f6;
  color: #282a2f;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`;
const Content = styled.div`
  cursor: pointer;
  padding: 8px 16px 8px 8px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
`;
