import { getApi } from './index';
import configUrl from './config';
import Cookies from 'js-cookie';
import { AxiosInstance } from 'axios';

type BodySearchService = {
  title: string | null;
};
type BodySearchSpecialistWorkplace = {
  title: string | null;
};

const config = (baseURL = configUrl.baseUrl) => {
  return {
    baseURL: `${baseURL}/api/v1/`,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('TOKEN') || ''}`,
    },
  };
};

const Methods = (instance: AxiosInstance) => ({
  /**
   * Методы для kanban карточки с услугами
   * @param search
   */
  get_all_client(search: string) {
    return instance.get(`client/getAll?search=${search}`, config());
  },
  get_search_services(body: BodySearchService) {
    return instance.get(`service/search?title=${body?.title}`, config());
  },
  get_specialist(body: BodySearchSpecialistWorkplace) {
    return instance.get(`specialist/getAll?search=${body?.title}`, config());
  },
  get_workplace(body: BodySearchSpecialistWorkplace) {
    return instance.get(`workplace/getAll?title=${body?.title}`, config());
  },
});

export const requestsSearch = getApi(Methods);
