import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';
import { WorkplaceDataProps } from './types';

type WorkplaceStateProps = {
  schedule: Record<string, any>;
  workplaces: any[];
  workplace: WorkplaceDataProps | null;
  count: number;
  search: Record<string, any>[];
};

const initialState: WorkplaceStateProps = {
  workplaces: [],
  workplace: null,
  count: 0,
  schedule: {},
  search: [],
};

export const workplaceSlice = createSlice({
  name: 'workplace',
  initialState,
  reducers: {
    //Сброс данных активного специалиста
    //---------------------------------
    resetData: (state: WorkplaceStateProps) => {
      state.workplace = null;
    },

    //---------------------------------
    resetSearch: (state: WorkplaceStateProps) => {
      state.search = [];
    },
    //Поиск услуг
    //---------------------------------
    searchServiceRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    searchServiceSuccess: (state: WorkplaceStateProps, action) => {
      state.search = action.payload?.services?.data;
    },

    //Получение расписания для специалистов
    //---------------------------------
    getScheduleRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    getScheduleSuccess: (state: WorkplaceStateProps, action) => {
      state.schedule = action.payload;
    },

    //Получение всех рабочих мест
    //---------------------------------
    getAllWorkplaceRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    getAllWorkplaceSuccess: (state: WorkplaceStateProps, action) => {
      state.workplaces = action.payload?.data;
      state.count = action.payload?.count;
    },
    //Получение рабочего места по Id
    //---------------------------------
    getWorkplaceIdRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    getWorkplaceIdSuccess: (state: WorkplaceStateProps, action) => {
      state.workplace = action.payload;
    },
    //Создание рабочего места
    //---------------------------------
    postCreateWorkplaceRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    postCreateWorkplaceSuccess: (state: WorkplaceStateProps, action) => {
      state.workplace = action.payload;
    },
    //Обновление рабочего места
    //---------------------------------
    postUpdateWorkplaceRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    postUpdateWorkplaceSuccess: (state: WorkplaceStateProps, action) => {
      state.workplace = action.payload;
    },
    //Удаление рабочего места
    //---------------------------------
    postDeleteWorkplaceRequest: (state: WorkplaceStateProps, action) => {
      //
    },
    postDeleteWorkplaceSuccess: (state: WorkplaceStateProps, action) => {
      // state.specialist = action.payload;
    },
  },
});

export const workplaceSelector = {
  workplaces: (s: RootState) => s.workplace.workplaces,
  workplace: (s: RootState) => s.workplace.workplace,
  count: (s: RootState) => s.workplace.count,
  schedule: (s: RootState) => s.workplace.schedule,
  search: (s: RootState) => s.workplace.search,
};
