import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { IconArrowDown } from 'icons';
import { Control } from './constrol';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { itemsDataServiceSelector, serviceSlice } from './serviceSlice';

type ItemParentServicePros = {
  data: Record<string, any>;
  changeService: (data?: Record<string, any>) => void;
  changeActive: (data?: Record<string, any>) => void;
  deleteService: (data?: Record<string, any>) => void;
  copyService: (data?: Record<string, any>) => void;
  left?: number;
  level?: number;
  addServiceCategory: (data?: Record<string, any>, level?: number) => void;
};

export const ItemService: React.FC<ItemParentServicePros> = ({
  data,
  changeService,
  changeActive,
  deleteService,
  copyService,
  addServiceCategory,
  left = 0,
  level = 1,
}) => {
  const dispatch = useAppDispatch();

  const handledClickTitle = (id: string | number) => () => {
    if (!data?.is_items) return;

    if (data?.is_items > 0) {
      dispatch(serviceSlice.actions.updateItemsData({ id, data: [] }));
    } else {
      dispatch(
        serviceSlice.actions.getAllServiceRequest({
          parent_id: id,
        }),
      );
    }
  };
  const valueLeft = left + 25;
  const isShow = useMemo(() => data?.is_items > 0, [data]);
  return (
    <>
      <Content left={valueLeft}>
        <WrapperTitle>
          {data?.is_items > 0 && (
            <WrapperIcon onClick={handledClickTitle(data?.id)} show={false}>
              <IconArrowDown />
            </WrapperIcon>
          )}

          <Title onClick={() => changeService(data)}>{data?.title}</Title>

          {data?.is_items > 0 && <Count>{`(${data?.is_items})`}</Count>}
        </WrapperTitle>
        <WrapperControl>
          <Control
            data={data}
            hiddenCopy={false}
            hiddenPlus={false}
            active={data?.is_active}
            setActive={() => changeActive(data)}
            setClickPencil={() => changeService(data)}
            setClickPlus={() => addServiceCategory(data, level)}
            setClickTrash={() => deleteService(data)}
            setClickCopy={() => copyService(data)}
          />
        </WrapperControl>
      </Content>
      {data?.children?.map((item: Record<string, any>) => (
        <ItemService
          key={item.id}
          level={level + 1}
          data={item}
          left={valueLeft}
          changeService={changeService}
          deleteService={deleteService}
          changeActive={changeActive}
          addServiceCategory={addServiceCategory}
          copyService={copyService}
        />
      ))}
    </>
  );
};

const Count = styled.div`
  font-size: 12px;
  color: var(--color-black-grey);
  padding-left: 5px;
`;

const WrapperIcon = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.33s ease-in-out;
  ${({ show }) => {
    if (show) {
      return css`
        svg {
          transform: rotate(-90deg);
          //transition: all 0.33s ease-in-out;
        }
      `;
    } else {
      return css`
        transform: rotate(0deg);
      `;
    }
  }}
`;
const WrapperControl = styled.div`
  padding-right: 9px;
  opacity: 0;
`;
const WrapperTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  user-select: none;
  margin-left: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  &:hover {
    text-decoration: underline;
  }
`;
const Content = styled.div<{ left?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ left }) => {
    return css`
      padding: 10px 0 10px ${left}px;
    `;
  }}
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grey-light);
  }
  &:hover {
    background: #f1f3f8;
  }
  &:hover ${WrapperControl} {
    opacity: 1;
  }
`;
