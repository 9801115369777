import React from 'react';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { TooltipPlacement } from 'antd/lib/tooltip';

type InfoPopUpProps = {
  symbol?: string;
  component?: React.ReactNode;
  children: React.ReactNode;
  width?: number;
  height?: number;
  zIndex?: number;
  placement?: TooltipPlacement;
};

export const InfoPopUp: React.FC<InfoPopUpProps> = ({
  symbol = 'i',
  width = 15,
  height = 15,
  placement = 'right',
  children,
  component,
  zIndex,
}) => {
  return (
    <Tooltip zIndex={zIndex} placement={placement} title={children} color={'#fff'}>
      {component ? component : <Content style={{ height, width }}>{symbol}</Content>}
    </Tooltip>
  );
};
const Content = styled.div`
  border-radius: 50%;
  cursor: pointer;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #6471e0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(100, 113, 224, 0.3);
`;
