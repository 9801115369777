import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

type ScheduleStateProps = {
  data: Record<string, any>;
};

const initialState: ScheduleStateProps = {
  data: [],
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    //Сброс данных активного специалиста
    //---------------------------------
    // resetData: (state: WorkplaceStateProps) => {
    //   state.workplace = null;
    // },

    //Получение расписания для специалистов
    //---------------------------------
    getScheduleRequest: (state: ScheduleStateProps, action) => {
      //
    },
    getScheduleSuccess: (state: ScheduleStateProps, action) => {
      state.data = action.payload;
    },
  },
});

export const scheduleSelector = {
  data: (s: RootState) => s.schedule.data,
};
