import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { clientsSlice } from '../clientsSlice';

function* prepareRequest(payload: any) {
  return payload.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(clientsSlice.actions.getClientSuccess(response));
  payload?.payload?.success?.(response);
}

function* prepareFailure(res: any) {
  yield;
}

export const getClientId = () => {
  return createRequestRest({
    url: '/api/v1/client/:id',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: clientsSlice.actions.getClientRequest,
    method: 'get',
  });
};
