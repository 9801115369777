import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { specialistSlice } from '../specialistSlice';
import { transformSpecialist } from '../utils';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  let data: any = [];
  if (response?.data) {
    payload?.payload?.success?.(response?.data);
    data = transformSpecialist(response?.data);
  }

  yield put(specialistSlice.actions.getAllSpecialistSuccess({ data, count: response?.count }));
}

function* prepareFailure(res: any) {
  yield;
}

export const getSpecialistAll = () => {
  return createRequestRest({
    url: '/api/v1/specialist/getAll',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: specialistSlice.actions.getAllSpecialistRequest,
    method: 'get',
  });
};
