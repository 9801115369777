import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrowDown, IconClose } from 'icons';
import { useMouseDown } from 'core/hooks';
import { ItemData } from './itemData';
import { activeClearItemSelectorTree, activeItemSelectorTree, getActiveDataSelector } from './utils';

export type ItemSelectProsTree = {
  id: number | symbol;
  category_id?: number;
  is_active?: boolean;
  active?: boolean;
  name?: string;
  items?: ItemSelectProsTree[];
};

type SelectorStaticPros = {
  placeholder?: string;
  data: ItemSelectProsTree[];
  onChange: (id: number | symbol | null) => void;
  valid?: boolean;
  isClear?: boolean;
  activeId?: number | symbol;
};

const initActive = { id: Symbol('id'), name: '' };

export const SelectorStaticTree: React.FC<SelectorStaticPros> = ({
  data,
  activeId,
  placeholder,
  onChange,
  valid = true,
  isClear = false,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [dataSelector, setDataSelector] = useState<ItemSelectProsTree[]>([]);
  const [active, setActive] = useState<ItemSelectProsTree>(() => ({ ...initActive, name: placeholder || '' }));

  useEffect(() => {
    setDataSelector(data);
  }, [data]);

  const handleClickItem = (data: ItemSelectProsTree) => {
    setShowList(false);
    setActive(data);
    const nextData = activeItemSelectorTree(dataSelector, data.id);
    setDataSelector(nextData);
    onChange(data.id);
  };

  const handleClickClear = () => {
    setActive({ ...initActive, name: placeholder || '' });
    const nextData = activeClearItemSelectorTree(dataSelector);
    setDataSelector(nextData);
    onChange(null);
  };

  useEffect(() => {
    if (activeId) {
      const nextData = activeItemSelectorTree(data, activeId);
      setDataSelector(nextData);

      const nextActive = getActiveDataSelector(data, activeId);

      if (nextActive) {
        setActive(nextActive);
      }
    }
  }, [activeId, data]);

  useMouseDown(() => setShowList(false), listRef, inputRef);

  return (
    <Content>
      <WrapperInput valid={!!valid} ref={inputRef} onClick={() => setShowList((prev) => !prev)}>
        <Title valid={!!valid} data={active.name !== placeholder}>
          {placeholder}
        </Title>
        {active.name !== placeholder && <Value valid={!!valid}>{active.name}</Value>}
      </WrapperInput>
      {showList && (
        <List ref={listRef}>
          {dataSelector.length ? (
            dataSelector.map((item) => <ItemData key={String(item?.id)} data={item} onChange={handleClickItem} />)
          ) : (
            <Info>Данные отсутствуют...</Info>
          )}
        </List>
      )}
      {isClear && active.name !== placeholder && active.name !== '' ? (
        <WrapperIconClose onClick={handleClickClear}>
          <IconClose />
        </WrapperIconClose>
      ) : (
        <WrapperIcon>
          <IconArrowDown />
        </WrapperIcon>
      )}
      {!valid && <ErrorText>Выберите категорию</ErrorText>}
    </Content>
  );
};
const ErrorText = styled.div`
  font-size: 10px;
  color: red;
  position: absolute;
  bottom: -18px;
  left: 0;
`;
const WrapperIconClose = styled.span`
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const Title = styled.span<{ valid: boolean; data: boolean }>`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.33s ease;

  ${({ valid, data }) => {
    if (data) {
      return css`
        top: 0;
        padding: 0 5px;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        background: var(--color-white);
        color: var(--color-black-grey);
      `;
    }
    if (valid) {
      return css`
        color: var(--color-black-grey);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};
`;
const Info = styled.div`
  padding: 50px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black-light);
`;

const List = styled.div`
  z-index: 9999;
  overflow-y: auto;
  width: 100%;
  max-height: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-white);
  transform: translateY(100%);
  border: 0.5px solid #dfe1e4;
  border-radius: 8px;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
`;
const WrapperIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 55%;
  transform: translateY(-50%);
  pointer-events: none;
`;
const WrapperInput = styled.div<{ valid: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  padding: 2px;
  height: 40px;
  ${({ valid }) => {
    if (valid) {
      return css`
        border: 1px solid #dfe1e4;
      `;
    } else {
      return css`
        border: 1px solid var(--color-red);
      `;
    }
  }};
`;
const Value = styled.div<{ valid: boolean }>`
  width: 100%;
  height: 34px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 48px 8px 16px;

  ${({ valid }) => {
    if (valid) {
      return css`
        color: var(--color-black-light);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};
`;
const Content = styled.div`
  position: relative;
`;
