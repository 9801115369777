import { all } from 'redux-saga/effects';
import { getAllTodoList } from './getAllTodoList';
import { createColumn } from './createColumn';
import { getAllColumnTodoList } from './getAllColumnTodoList';
import { removeColumn } from './removeColumn';
import { createCard } from './createCard';
import { getCardId } from './getCardId';
import { updateCard } from './updateCard';
import { removeCard } from './removeCard';
import { updateColumn } from './updateColumn';
import { updateSortColumn } from './updateSortColumn';
import { updateSortTask } from './updateSortTask';

export default function* kanbanSaga() {
  yield all([
    getAllTodoList(),
    createColumn(),
    getAllColumnTodoList(),
    removeColumn(),
    createCard(),
    getCardId(),
    updateCard(),
    removeCard(),
    updateColumn(),
    updateSortColumn(),
    updateSortTask(),
  ]);
}
