import React from 'react';
import locale from 'antd/es/locale/ru_RU';
import './index.scss';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import 'dayjs/locale/ru';
import CustomInputMask from './customInputMask';

type DataPicker = DatePickerProps;
export const BaseDataPicker: React.FC<DataPicker> = (props) => {
  return (
    <DatePicker
      locale={locale.DatePicker}
      format={'DD.MM.YYYY'}
      inputRender={(p) => <CustomInputMask {...p} />}
      {...props}
    />
  );
};
