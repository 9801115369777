import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ItemNavBar } from './type';

type ItemChildProps = {
  data: ItemNavBar;
  onClick?: () => void;
  hidden?: boolean;
};

export const ItemChild: React.FC<ItemChildProps> = ({ data, hidden }) => {
  return (
    <Link to={data.path}>
      <Content active={data?.active} hid={hidden}>
        {data?.title}
      </Content>
    </Link>
  );
};

const Content = styled.div<{ active?: boolean; hid?: boolean }>`
  color: var(--color-white);
  font-weight: 400;
  white-space: nowrap;
  font-size: 13px;
  line-height: 20px;
  border-radius: 4px;
  padding: ${({ hid }) => (hid ? `10px 9px` : `10px 9px 10px 48px`)};
  background: ${({ active }) => (active ? `var(--color-purple-light)` : `var(--color-purple)`)};
`;
