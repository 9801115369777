import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconArrowDown, IconClose } from 'icons';
import { useMouseDown } from 'core/hooks';
export type ItemSelectPros = {
  id: string | symbol;
  value: number | string;
  data?: string | Record<string, any>;
};
type SelectorStaticPros = {
  placeholder?: string;
  data: ItemSelectPros[];
  onChange: (d: ItemSelectPros) => void;
  valid?: boolean;
  isClear?: boolean;
  valueId?: string;
};

const initActive = { id: '-1', value: '' };

export const SelectorStatic: React.FC<SelectorStaticPros> = ({
  data,
  valueId,
  placeholder,
  onChange,
  valid = true,
  isClear = false,
}) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [active, setActive] = useState<ItemSelectPros>(() => ({ ...initActive, value: placeholder || '' }));

  const handleClickItem = (data: ItemSelectPros) => () => {
    setActive(data);
    onChange(data);
    setShowList(false);
  };

  const handleClickClear = () => {
    setActive({ ...initActive, value: placeholder || '' });
    onChange({
      data: '',
    } as ItemSelectPros);
  };

  useEffect(() => {
    if (!valueId) return;
    const findItem = data.find((item) => item.id === valueId);
    if (!findItem) {
      const symbolValue = data.find((item) => typeof item.id === 'symbol');
      symbolValue && setActive(symbolValue);
    }
    findItem && setActive(findItem);
  }, [valueId, data]);

  useMouseDown(() => setShowList(false), listRef, inputRef);

  return (
    <Content>
      <WrapperInput valid={!!valid} ref={inputRef} onClick={() => setShowList((prev) => !prev)}>
        <Title valid={!!valid} data={active.value !== placeholder}>
          {placeholder}
        </Title>
        {active.value !== placeholder && <Value valid={!!valid}>{active.value}</Value>}
      </WrapperInput>
      {showList && (
        <List ref={listRef}>
          {data.length ? (
            data.map((item) => (
              <Item key={item.id.toString()} onClick={handleClickItem(item)}>
                {item.value}
              </Item>
            ))
          ) : (
            <Info>Данные отсутствуют...</Info>
          )}
        </List>
      )}
      {isClear && active.value !== placeholder && active.value !== '' ? (
        <WrapperIconClose onClick={handleClickClear}>
          <IconClose />
        </WrapperIconClose>
      ) : (
        <WrapperIcon>
          <IconArrowDown />
        </WrapperIcon>
      )}
    </Content>
  );
};
const WrapperIconClose = styled.span`
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

const Title = styled.span<{ valid: boolean; data: boolean }>`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.33s ease;

  ${({ valid, data }) => {
    if (data) {
      return css`
        top: 0;
        padding: 0 5px;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        background: var(--color-white);
        color: var(--color-black-grey);
      `;
    }
    if (valid) {
      return css`
        color: var(--color-black-grey);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};
`;
const Info = styled.div`
  padding: 50px 0;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black-light);
`;
const Item = styled.div`
  cursor: pointer;
  padding: 7px 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  cursor: pointer;
  &:hover {
    background: var(--color-grey-light);
  }
`;
const List = styled.div`
  z-index: 9999;
  overflow-y: auto;
  width: 100%;
  max-height: 400px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--color-white);
  transform: translateY(100%);
  border: 0.5px solid #dfe1e4;
  border-radius: 8px;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
`;
const WrapperIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;
const WrapperInput = styled.div<{ valid: boolean }>`
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  padding: 2px;
  height: 40px;
  ${({ valid }) => {
    if (valid) {
      return css`
        border: 1px solid #dfe1e4;
      `;
    } else {
      return css`
        border: 1px solid var(--color-red);
      `;
    }
  }};
`;
const Value = styled.div<{ valid: boolean }>`
  width: 100%;
  height: 34px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 48px 8px 16px;

  ${({ valid }) => {
    if (valid) {
      return css`
        color: var(--color-black-light);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};
`;
const Content = styled.div`
  position: relative;
`;
