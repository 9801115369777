import React from 'react';
import styled from 'styled-components';
import { SpecialistProps } from './types';
import config from 'api/config';

type ItemSpecialistProps = {
  data: SpecialistProps;
  onClick: () => void;
};

export const ItemSpecialist: React.FC<ItemSpecialistProps> = (p) => {
  const url = p?.data?.image ? `${config.baseUrlImg}${p?.data?.image?.file_path}` : '#';
  return (
    <Content onClick={p.onClick}>
      <WrapperAvatar>
        <Avatar src={url} />
      </WrapperAvatar>
      <WrapperInfo>
        <Name>{p?.data?.name}</Name>
        <Profession>{p?.data?.profession}</Profession>
      </WrapperInfo>
    </Content>
  );
};
const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  object-fit: cover;
`;
const Profession = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #82889a;
  margin-top: 4px;
`;
const Name = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 155px;
`;
const WrapperInfo = styled.div``;
const WrapperAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background: #eff1f4;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  img[src='#'] {
    display: none;
  }
`;
const Content = styled.div`
  cursor: pointer;
  padding: 8px 16px 8px 8px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
`;
