import React from 'react';
import styled from 'styled-components';
import { ButtonPros } from 'core/types';
import { renderButtons } from '../utils';

type HeaderProps = {
  title: string;
  buttons?: ButtonPros[];
  content?: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({ title, buttons, content }) => {
  return (
    <Content>
      <LeftContent>
        <Title>{title}</Title>
        {content}
      </LeftContent>

      <BlockButton>{renderButtons(buttons)}</BlockButton>
    </Content>
  );
};
const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--color-black);
  margin-right: 32px;
`;
const BlockButton = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
