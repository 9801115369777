import createRequestRest from 'api/createRequestRest';
import { serviceSlice } from '../serviceSlice';
import { put } from 'redux-saga/effects';

function* prepareRequest(payload: any) {
  return { id: payload?.payload?.data?.id };
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(serviceSlice.actions.deleteCategorySuccess(payload?.payload?.data));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const deleteCategory = () => {
  return createRequestRest({
    url: '/api/v1/service/category/remove',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.deleteCategoryRequest,
    method: 'delete',
  });
};
