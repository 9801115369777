import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconCloseNB, IconLogo } from 'icons';
import { ItemParent } from './itemParent';
import { ItemNavBar } from './type';
import { initDataNavBar } from './data';
import { setActive, toggleShow } from './utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routingApi } from 'core/apiRouting';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { authSlice, userAuthSelector } from 'pages/auth/authSlice';

export const Navbar: React.FC = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userAuthSelector);
  const [data, setData] = useState<ItemNavBar[]>(initDataNavBar);
  const [hidden, setHidden] = useState<boolean>(false);
  const handleToggleShow = (id: number) => {
    setData((prev) => toggleShow(prev)(id));
  };

  const renderNavBar = (data: ItemNavBar[], handleToggleShow: (id: number) => void, hidden: boolean) =>
    data.map((item) => (
      <ItemParent key={item.id} data={item} toggleShow={() => handleToggleShow(item.id)} hidden={hidden} />
    ));

  const toggleHidden = () => {
    setHidden((prev) => !prev);
  };

  const handleClickLogout = () => {
    dispatch(
      authSlice.actions.getLogoutRequest({
        payload: {
          data: { user_id: user?.id },
          success: () => {
            navigation('/auth/sign_in');
            localStorage.removeItem('email_login_user');
            dispatch(authSlice.actions.resetUser());
            dispatch(authSlice.actions.resetSpace());
          },
        },
      }),
    );
    dispatch({ type: 'USER_LOGOUT' });
  };

  useEffect(() => {
    setData((prev) => setActive(prev)(location.pathname));
  }, [location.pathname]);

  return (
    <Content hid={hidden}>
      <Header hid={hidden}>
        {!hidden && (
          <Link to={routingApi.crm.link}>
            <IconLogo />
          </Link>
        )}
        <WrapperCloseIcon onClick={toggleHidden} hidden={hidden} />
      </Header>
      <Block>
        <Body>{renderNavBar(data, handleToggleShow, hidden)}</Body>
        {!hidden && (
          <InfoUser>
            <UserTitle>{`Name: ${user?.name}`}</UserTitle>
            <UserTitle>{`Email: ${user?.email}`}</UserTitle>
            <UserLogout onClick={handleClickLogout}>Logout</UserLogout>
          </InfoUser>
        )}
      </Block>
    </Content>
  );
};
const Block = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const UserLogout = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;
const UserTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
`;
const InfoUser = styled.div`
  left: 0;
  bottom: 0;
  padding: 20px;
  color: #dfe1e4;
`;
const Body = styled.div`
  margin-top: 24px;
`;
const Header = styled.div<{ hid: boolean }>`
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ hid }) =>
    hid
      ? css`
          justify-content: center;
        `
      : null}
`;
const Content = styled.div<{ hid: boolean }>`
  min-height: 100%;

  min-width: ${({ hid }) => (hid ? `48px` : `220px`)};
  padding: 24px 0;
  background: var(--color-purple);
  ${({ hid }) =>
    hid
      ? css`
          min-width: 48px;
        `
      : css`
          min-width: 220px;
        `}
`;

const WrapperCloseIcon = styled(IconCloseNB)<{ hidden: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: ${({ hidden }) => (hidden ? `rotate(180deg)` : `rotateX(0deg)`)};
`;
