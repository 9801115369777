import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ItemNavBar } from './type';
import { ItemChild } from './itemClild';

type ItemParentProps = {
  data: ItemNavBar;
  toggleShow: () => void;
  hidden: boolean;
};

export const ItemParent: React.FC<ItemParentProps> = ({ data, toggleShow, hidden }) => {
  const { icon, title, show = false, active = false, path, child = [] } = data;
  const IconNavBar = icon && icon;

  const handleClick = () => {
    toggleShow();
  };
  return (
    <WrapperContent>
      <Content active={active}>
        {data.child?.length ? (
          <Header onClick={handleClick} hid={hidden}>
            {!!icon && <IconNavBar />}
            {!hidden && <Title>{title}</Title>}
          </Header>
        ) : (
          <Link to={data.path}>
            <Header onClick={handleClick} hid={hidden}>
              {!!icon && <IconNavBar />}
              {!hidden && <Title>{title}</Title>}
            </Header>
          </Link>
        )}
        {hidden ? (
          <HiddenBlock contentValue={!!data.child?.length}>
            <HiddenContent contentValue={!!data.child?.length}>
              {child.map((child) => (
                <ItemChild key={child.id} data={child} hidden={hidden} />
              ))}
            </HiddenContent>
          </HiddenBlock>
        ) : (
          <Body>{show && child.map((child) => <ItemChild key={child.id} data={child} />)}</Body>
        )}
      </Content>
    </WrapperContent>
  );
};

//
const HiddenContent = styled.div<{ contentValue: boolean }>`
  padding: ${({ contentValue }) => (contentValue ? '8px' : '0px')};
  border-radius: 8px;
  background: var(--color-purple);
`;
const HiddenBlock = styled.div<{ contentValue: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ contentValue }) => (contentValue ? '5px' : '0px')};
  opacity: 0;
  position: absolute;
  top: 0;
  right: -8px;
  transform: translateX(100%);
  z-index: -1;
  transition: all 0.33s ease;
`;
const Header = styled.div<{ hid: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 9px;
  ${({ hid }) =>
    hid
      ? css`
          justify-content: center;
        `
      : null}
`;
const Body = styled.div``;
const Title = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  margin-left: 5px;
  white-space: nowrap;
`;
const WrapperContent = styled.div`
  padding: 0 8px;
  cursor: pointer;
  &:hover ${HiddenBlock} {
    opacity: 1;
    z-index: 9999;
  }
`;
const Content = styled.div<{ active?: boolean }>`
  position: relative;
  border-radius: 4px;
  background: ${({ active }) => (active ? `var(--color-purple-light)` : `var(--color-purple)`)};
`;
