import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { serviceSlice } from '../serviceSlice';

function* prepareRequest(data: { id: number }) {
  return data;
  yield;
}

function* prepareSuccess(response: any, payload: { id: number }) {
  yield put(serviceSlice.actions.getCategoryIdSuccess(response));
}

function* prepareFailure(res: any) {
  yield;
}

export const getCategoryId = () => {
  return createRequestRest({
    url: '/api/v1/service/category/:id',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: serviceSlice.actions.getCategoryIdRequest,
    method: 'get',
  });
};
