import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { authSlice } from '../authSlice';
import Cookies from 'js-cookie';

function* prepareRequest(data: Record<string, any>) {
  return data.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: Record<string, any>) {
  Cookies.set('TOKEN', response?.token);

  yield put(authSlice.actions.getConfirmSuccess(response));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const getConfirm = () => {
  return createRequestRest({
    url: '/api/v1/auth/register/confirm',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: authSlice.actions.getConfirmRequest,
    method: 'get',
  });
};
