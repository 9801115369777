import React, { useState } from 'react';
import styled from 'styled-components';
import { mockData } from './mockData';
import { ItemRowTypes } from './types';
import { RenderRows } from './helpers';

export const Table: React.FC = () => {
  const [rows, setRows] = useState<Record<number, ItemRowTypes>>(mockData);

  return (
    <Content>
      <Header>
        <TitleHeader>№</TitleHeader>
        <TitleHeader>Название очередности</TitleHeader>
        <TitleHeader>Обязательный выбор</TitleHeader>
        <TitleHeader>Активный шаг</TitleHeader>
      </Header>
      <RenderRows data={rows} />
    </Content>
  );
};
const TitleHeader = styled.div`
  color: #6b6f76;
  font-size: 12px;
  font-weight: 400;
`;
const Header = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr 180px 100px;
  grid-template-rows: 48px;
  background: #f9f9f9;
  align-items: center;
  padding: 0 15px 0 55px;
`;
const Content = styled.div``;
