import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

type RangeProps = {
  onChange?: (v: number) => void;
};

export const Range: React.FC<RangeProps> = ({ onChange }) => {
  const [value, setValue] = useState(0.5);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(+e.target.value);
    onChange?.(+e.target.value);
  };
  return (
    <WrapperContent>
      <Content type={'range'} value={value} onChange={handleChange} step={0.5} min={0} max={1} list='values' />
      <WrapperOptions>
        <Options />
      </WrapperOptions>
    </WrapperContent>
  );
};
const WrapperOptions = styled.div`
  padding: 0 5px 0 9px;
`;
const Options = styled.div`
  height: 4px;
  border-left: 1px solid #6b6f76;
  border-right: 1px solid #6b6f76;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    background: #6b6f76;
    position: absolute;
    left: 50%;
    top: 0;
  }
`;
const WrapperContent = styled.div`
  width: 100%;
`;
const Content = styled.input`
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  margin-right: 15px;
  height: 7px;
  background: #caceef;
  border-radius: 5px;

  &[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border: 1px solid #6471e0;
    border-radius: 50%;
    background: #ffffff;
  }
`;
