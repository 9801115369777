import { FilterDataProps } from './filter';
import { FilterParentComplexProps } from './filterComplex';
import { MultiFilterDataProps } from './multiFilter';

export const activeMultiFilter = (value: any, id: string | number, data: Record<string, any>) => {
  return Object.entries(data).reduce((acc, [key, item]) => {
    if (item.id === id) {
      return { ...acc, [key]: { ...item, value, active: !!value } };
    } else return acc;
  }, data);
};
export const getFilterRequest = (data: Record<string, any>) => {
  return Object.values(data).reduce((acc, item) => {
    if (item.value) {
      return { ...acc, [item.query]: item.value };
    } else return acc;
  }, {});
};
export const countActiveMultiFilter = (data: Record<string, any>): number =>
  Object.values(data).reduce((acc, item) => (item.active ? acc + 1 : acc), 0);

export const countActiveFilter = (data: FilterDataProps[]): number =>
  data.reduce((acc, item) => (item.active ? acc + 1 : acc), 0);

export const changeActiveItemFilter = (data: FilterDataProps[], item: FilterDataProps): FilterDataProps[] =>
  data.map((i) => (i.id === item.id ? { ...i, active: !item.active } : i));

export const changeActiveFilterComplex = (
  data: FilterParentComplexProps[],
  item: FilterParentComplexProps,
  id: number,
): FilterParentComplexProps[] =>
  data.map((i) =>
    i.id === item.id ? { ...item, items: i.items.map((j) => (j.id === id ? { ...j, active: !j.active } : j)) } : i,
  );

export const countFilterComplex = (data: FilterParentComplexProps[]): number => {
  let count = 0;
  data?.map((item) => {
    item.items.map((i) => {
      if (i.active) {
        count = count + 1;
      }
    });
  });

  return count;
};
