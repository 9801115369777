import React, { useState } from 'react';

type WorkplaceProps = {
  data?: Record<string, any>;
  onIsValid?: () => void;
};

type StateProps = {
  workplace: WorkplaceProps | null;
};

type TabsContextProps = {
  state?: StateProps;
  setState?: (value?: ((prevState?: StateProps) => StateProps) | StateProps) => void;
};

export const TabsContext = React.createContext<TabsContextProps | undefined>(undefined);

export const TabsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<StateProps>();

  return <TabsContext.Provider value={{ state, setState }}>{children}</TabsContext.Provider>;
};

export const useTabsContext = () => {
  const context = React.useContext(TabsContext);
  if (context === undefined) {
    throw new Error('useConfigState must be used within a ConfigProvider');
  }
  return context;
};
