import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { clientsSlice } from '../clientsSlice';

function* prepareRequest(payload: any) {
  return payload.payload.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  payload?.payload?.success?.();

  yield put(clientsSlice.actions.postCreateClientSuccess(response));
  // yield put(clientsSlice.actions.getAllClientsRequest({ payload: { data: response } }));
}

function* prepareFailure(res: any) {
  yield;
}

export const postCreateClients = () => {
  return createRequestRest({
    url: '/api/v1/client/create',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: clientsSlice.actions.postCreateClientRequest,
    method: 'post',
  });
};
