import React from 'react';
import styled from 'styled-components';
import { ItemTable } from './types';
import { IconEmail, IconPencil, IconTrash } from 'icons';

type RowProps = {
  grid: string;
  data: ItemTable[];
  handleChangeRow: (id?: number, remove?: boolean) => void;
};

export const Row: React.FC<RowProps> = ({ grid, data, handleChangeRow }) => {
  const handleClickPencil = (id?: number, remove?: boolean) => () => {
    handleChangeRow(id, remove);
  };
  return (
    <Content grid={grid}>
      {data.map((item) => (
        <Item key={item.id}>{item.value}</Item>
      ))}
      <Control>
        <ItemButton onClick={handleClickPencil(data[0]?.clientId)}>
          <IconPencil />
        </ItemButton>
        <ItemButton>
          <IconEmail />
        </ItemButton>
        <ItemButton onClick={handleClickPencil(data[0]?.clientId, true)}>
          <IconTrash />
        </ItemButton>
      </Control>
    </Content>
  );
};

const Item = styled.div`
  flex: 1;
  min-width: 100%;
  width: max-content;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
`;
const Control = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  z-index: -1;
`;
const Content = styled.div<{ grid: string }>`
  position: relative;
  cursor: pointer;
  width: 100%;
  display: grid;
  justify-content: space-between;
  gap: 10px;
  grid-template-columns: ${({ grid }) => grid};
  padding: 12px 30px;
  &:not(:last-child) {
    border-bottom: 1px solid #eff1f4;
  }
  &:hover {
    background: #f1f3f8;
  }
  &:hover ${Control} {
    opacity: 1;
    z-index: 1;
  }
`;

const ItemButton = styled.div`
  width: 24px;
  height: 24px;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #dfe1e4;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
`;
