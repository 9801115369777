export const ItemTypes = {
  ROW_SETTINGS: 'row-settings',
};

export type ItemRowTypes = {
  id: number;
  number: number;
  name: string;
  require: boolean;
  active: boolean;
  parentId: number | null;
};
