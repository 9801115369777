import { KartProps, TypeColumnProps } from './types';
import { v4 as idV4 } from 'uuid';

const backFront: Record<string, TypeColumnProps> = {
  await: 'pending',
  inProgress: 'in_work',
  completed: 'completed',
  cancel: 'canceled',
};

export const transformKart = (data: Record<string, any>): KartProps => {
  return {
    id: data.id,
    time: data?.time_at,
    cabinet: data?.workplace?.title,
    service: data?.service?.title,
    doctor: [data?.specialist?.surname, data?.specialist?.name, data?.specialist?.patronymic].join(' '),
    client: [data?.client?.surname, data?.client?.name, data?.client?.patronymic].join(' '),
    phone: data?.client?.phone,
  };
};

export const transformColumn = (data: Record<string, any>[]) => {
  return [
    ...((data || [])
      ?.filter((item) => !item?.is_archive)
      ?.map((i) => ({
        id: i?.id,
        name: i?.name,
        prefix: idV4(),
      })) || []),
    // { id: Symbol('id'), name: 'new_column', prefix: idV4(), sort: null },
  ];
};

export const transformSearchClients = (data: Record<string, any>[]) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    email: item.email,
    phone: item.phone,
    date: item.birthday_at,
  }));
};
