import React from 'react';
import styled, { css } from 'styled-components';

type RadioInputProps = {
  title: string;
  type: string;
  value: string;
  onClick: (type: string) => void;
};

export const RadioInput: React.FC<RadioInputProps> = ({ title, type, value, onClick }) => {
  return (
    <Wrapper>
      <Content active={type === value} onClick={() => onClick(type)} />
      <Title>{title}</Title>
    </Wrapper>
  );
};
const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  content: var(--color-black-light);
  margin-left: 16px;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Content = styled.div<{ active: boolean }>`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #6b6f76;
  position: relative;

  &:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #caceef;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ active }) =>
    active
      ? css`
          &:after {
            opacity: 1;
          }
        `
      : css`
          &:after {
            opacity: 0;
          }
        `}
`;
