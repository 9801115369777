import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { InfoPopUp } from 'components/infoPopUp';
import { InputSearch } from 'components/inputSearch';
import { FilterComplex, FilterParentComplexProps } from 'components/filters/filterComplex';
import { initFilters } from './helpers';
import { ItemColumn } from './itemColumn';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { kanbanSelector, kanbanSlice } from './kanbanSlice';
import { AddColumn } from './itemColumn/addColumn';
import { Range } from '../../components/range';
import { TabsContextProvider } from './tabsContent/context';
import { Switch } from '../../components/switch';

const KanbanContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const [widthColumn, setWidthColumn] = useState(344);
  const archive = useAppSelector(kanbanSelector.archive);
  const columns = useAppSelector(kanbanSelector.columns);
  const data = useAppSelector(kanbanSelector.data);
  const [filters, setFilters] = useState<FilterParentComplexProps[]>(initFilters);
  const [showArchive, setShowArchive] = useState(false);
  const handleOnChange = (value: number) => {
    switch (value) {
      case 0:
        setWidthColumn(250);
        break;
      case 1:
        setWidthColumn(420);
        break;
      default:
        setWidthColumn(344);
    }
  };
  useEffect(() => {
    if (archive && data && data[archive.id]?.length) {
      setShowArchive(true);
    }
  }, [archive]);

  useEffect(() => {
    dispatch(kanbanSlice.actions.getAllTodoListRequest());
    return () => {
      dispatch(kanbanSlice.actions.resetArchive());
    };
  }, []);

  useEffect(() => {
    if (showArchive) {
      dispatch(kanbanSlice.actions.addArchiveColumn());
    } else {
      dispatch(kanbanSlice.actions.deleteArchiveColumn());
    }
  }, [showArchive]);

  const handleChangeFilter = (data: FilterParentComplexProps[]) => {
    setFilters(data);
  };

  const onDragEnd = (result: any) => {
    if (result.type === 'card' && result?.destination?.index !== undefined) {
      if (result?.destination?.droppableId === result?.source?.droppableId) {
        /**
         * Перемещение карточек в колонке
         * Сначала отправляем запрос на бэк если отработал то на фронте меняем местами
         */
        const cardCopyPrev =
          result?.destination?.droppableId && data && Array.isArray(data[result.destination.droppableId])
            ? Array.from(data[result.destination.droppableId])
            : [];
        const cardCopy =
          result?.destination?.droppableId && data && Array.isArray(data[result.destination.droppableId])
            ? Array.from(data[result.destination.droppableId])
            : [];
        const [removed] = cardCopy.splice(result.source.index, 1);
        cardCopy.splice(result.destination.index, 0, removed);

        if (result.destination.droppableId && cardCopy?.length)
          dispatch(
            kanbanSlice.actions.updateCardColumn({
              id: result.destination.droppableId,
              cards: cardCopy,
            }),
          );

        //TODO: отправка на бэк
        const columnId = result.destination.droppableId;
        const todolistIds = cardCopy.map((item) => item.id).filter((item) => item !== 'archive');

        dispatch(
          kanbanSlice.actions.updateSortTaskRequest({
            payload: {
              data: {
                columnId,
                todolistIds,
              },
              failure: () => {
                //ERROR: вернем как было
                dispatch(
                  kanbanSlice.actions.updateCardColumn({
                    id: result.destination.droppableId,
                    cards: cardCopyPrev,
                  }),
                );
              },
            },
          }),
        );
      } else {
        /**
         * Перемещение карточек между колонками
         * Сначала отправляем запрос на бэк если отработал то на фронте меняем местами
         */
        const cardsCopyColumnDestPrev =
          result?.destination?.droppableId && data && Array.isArray(data[result.destination.droppableId])
            ? Array.from(data[result.destination.droppableId])
            : [];

        const cardsCopyColumnDest =
          result?.destination?.droppableId && data && Array.isArray(data[result.destination.droppableId])
            ? Array.from(data[result.destination.droppableId])
            : [];

        const cardsCopyColumnSourcePrev =
          result?.source?.droppableId && data && Array.isArray(data[result.source.droppableId])
            ? Array.from(data[result.source.droppableId])
            : [];

        const cardsCopyColumnSource =
          result?.source?.droppableId && data && Array.isArray(data[result.source.droppableId])
            ? Array.from(data[result.source.droppableId])
            : [];

        const removed = cardsCopyColumnSource[result.source.index];

        // const [removed] = cardCopy.splice(result.source.index, 1);
        cardsCopyColumnDest.splice(result.destination.index, 0, removed);
        const nextCardsSource = cardsCopyColumnSource.filter((item) => item.id !== removed.id);
        if (
          result.destination.droppableId &&
          result?.source?.droppableId &&
          cardsCopyColumnDest?.length &&
          cardsCopyColumnSource?.length
        )
          dispatch(
            kanbanSlice.actions.updateCardColumns({
              idDest: result.destination.droppableId,
              cardsDest: cardsCopyColumnDest,
              idSource: result?.source?.droppableId,
              cardsSource: nextCardsSource,
            }),
          );

        const columnId = result.destination.droppableId;
        const todolistIds = cardsCopyColumnDest.map((item) => item.id);

        dispatch(
          kanbanSlice.actions.updateSortTaskRequest({
            payload: {
              data: {
                columnId,
                todolistIds,
              },
              failure: () => {
                //ERROR: вернем как было
                dispatch(
                  kanbanSlice.actions.updateCardColumns({
                    idDest: result.destination.droppableId,
                    cardsDest: cardsCopyColumnDestPrev,
                    idSource: result?.source?.droppableId,
                    cardsSource: cardsCopyColumnSourcePrev,
                  }),
                );
              },
            },
          }),
        );

        //TODO: отправка на бэк
      }
    }
    if (result.type === 'column' && result?.destination?.index !== undefined) {
      /**
       * Перемещение колонок
       * Сначала отправляем запрос на бэк если отработал то на фронте меняем местами
       */
      const columnsCopy = columns ? Array.from(columns) : [];
      const columnsCopyPrev = columns ? Array.from(columns) : []; // данные для возврата

      const [removed] = columnsCopy.splice(result.source.index, 1);
      columnsCopy.splice(result.destination.index, 0, removed);

      dispatch(kanbanSlice.actions.updateColumns(columnsCopy));
      //данные которые пойдут на бэк
      const sortIds = columnsCopy.map((item) => item.id).filter((item) => item !== 'archive');

      // TODO: сохранение порядка колонок если не сохранился вернуть как было
      dispatch(
        kanbanSlice.actions.updateSortColumnRequest({
          payload: {
            data: { sortIds },
            failure: () => {
              //ERROR: вернем как было
              dispatch(kanbanSlice.actions.updateColumns(columnsCopyPrev));
            },
          },
        }),
      );
    }
  };

  //TODO: прикрутить лоадер при сохрании перемещения
  return (
    <Content>
      <Head>
        <Title>Расписание</Title>
        <Stock>
          <TitleStock>Акции:</TitleStock>
          <WrapperStock>
            <StockText>23 февраля</StockText>
            <InfoPopUp>23 февраля</InfoPopUp>
          </WrapperStock>
          <WrapperStock>
            <StockText>8 марта</StockText>
            <InfoPopUp>8 марта</InfoPopUp>
          </WrapperStock>
          <WrapperStock>
            <StockText>Новый клиент</StockText>
            <InfoPopUp>Новый клиент</InfoPopUp>
          </WrapperStock>
        </Stock>
      </Head>

      <BLockFilters>
        <Filters>
          <WrapperFilter>
            <FilterComplex data={filters} onChange={handleChangeFilter} />
          </WrapperFilter>
          <WrapperFilter>
            {/*<Sorting icon={IconOrders} label={'Записи и Заказы'} data={dataSortingOrders} onChange={() => undefined} />*/}
          </WrapperFilter>
          <WrapperFilter>{/*<Sorting data={dataSorting} onChange={() => undefined} />*/}</WrapperFilter>
          <WrapperFilter>
            {/*<Sorting icon={IconCalendarFilter} label={'Неделя'} data={dataSortingCalendar} onChange={() => undefined} />*/}
          </WrapperFilter>
          <InputSearch title='Поиск' placeholder='Поиск' />
        </Filters>
        <RangeBlock>
          <TitleRange>Ширина колонок:</TitleRange>
          <WrapperRange>
            <Range onChange={handleOnChange} />
          </WrapperRange>
        </RangeBlock>
      </BLockFilters>
      <WrapperSwitch>
        <Switch active={showArchive} setActive={() => setShowArchive((prev) => !prev)} title='Показать архив' />
      </WrapperSwitch>
      <WrapperBody>
        <Body>
          {data && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={'dashboard'} direction={'horizontal'} type={'column'}>
                {(provided) => {
                  return (
                    <WrapperColumns
                      count={columns?.length}
                      value={`${widthColumn}px`}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {columns?.map((item, index) => {
                        if (item.id === archive?.id) {
                          return (
                            <WrapperColumn key={item.id?.toString()}>
                              <ItemColumn item={item} items={data[item.id]} />
                            </WrapperColumn>
                          );
                        } else
                          return (
                            <>
                              <Draggable key={item.id?.toString()} draggableId={item.prefix} index={index}>
                                {(provided: any, snapshot: any) => {
                                  return (
                                    <WrapperColumn
                                      ref={provided.innerRef}
                                      snapshot={snapshot}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <ItemColumn item={item} items={data[item.id]} />
                                    </WrapperColumn>
                                  );
                                }}
                              </Draggable>
                            </>
                          );
                      })}
                      <AddColumn />
                    </WrapperColumns>
                  );
                }}
              </Droppable>
            </DragDropContext>
          )}
        </Body>
      </WrapperBody>
    </Content>
  );
};

export const Kanban: React.FC = () => (
  <TabsContextProvider>
    <KanbanContent />
  </TabsContextProvider>
);
const WrapperSwitch = styled.div`
  margin: 10px 0;
`;
const TitleRange = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #6b6f76;
  margin-right: 16px;
`;
const RangeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const WrapperRange = styled.div`
  width: 80px;
`;
const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
`;
const WrapperColumns = styled.div<{ value: string; count?: number }>`
  display: grid;
  grid-template-columns: ${({ value, count }) => `repeat(${count}, ${value}) 48px`};
  column-gap: 10px;
  transition: all 0.33s ease;
  min-height: 100%;
`;
const WrapperColumn = styled.div`
  height: 100%;
`;
const WrapperBody = styled.div`
  height: calc(100vh - 150px);
  width: calc(100vw - 252px);
  overflow: auto;
  padding-right: 20px;
`;
const Body = styled.div`
  height: 100%;
`;
const WrapperFilter = styled.div`
  &:not(:last-child) {
    margin-right: 15px;
  }
`;
const WrapperStock = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 20px;
  }
`;
const StockText = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
  margin-right: 5px;
  white-space: nowrap;
`;
const TitleStock = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #6b6f76;
  margin-right: 12px;
`;
const Filters = styled.div`
  display: flex;
  align-items: center;
`;
const Stock = styled.div`
  display: flex;
  align-items: center;
`;
const BLockFilters = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;
`;

const Content = styled.div`
  padding: 24px 0 0 32px;
  width: 100%;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #282a2f;
`;
