import { ItemRowTypes } from './types';
import React from 'react';
import { ItemRow } from './itemRow';

type RenderRowsProps = {
  data: Record<number, ItemRowTypes>;
  parentId?: null | number;
  indentationLeft?: number;
};
export const RenderRows: React.FC<RenderRowsProps> = ({ data, parentId = null, indentationLeft = 0 }) => {
  const items = Object.values(data).filter((item) => item.parentId === parentId);

  return (
    <>
      {items.map((item) => (
        <React.Fragment key={item.id}>
          <ItemRow data={item} indentationLeft={indentationLeft} />
          <RenderRows data={data} parentId={item.id} indentationLeft={25} />
        </React.Fragment>
      ))}
    </>
  );
};
