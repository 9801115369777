import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { IconSearch } from 'icons';

type InputSearchProps = InputHTMLAttributes<HTMLInputElement> & {
  title: string;
  placeholder?: string;
};

export const InputSearch: React.FC<InputSearchProps> = (props) => {
  const { title, placeholder } = props;
  return (
    <Content>
      <Input required placeholder={placeholder} {...props} />
      <BLockIcon>
        <WrapperIcon>
          <IconSearch />
        </WrapperIcon>
        <Title>{title}</Title>
      </BLockIcon>
    </Content>
  );
};

const Content = styled.div`
  position: relative;
`;
const BLockIcon = styled.div`
  opacity: 1;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  pointer-events: none;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg {
    path {
      stroke: var(--color-blue);
    }
  }
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-blue);
`;
const Input = styled.input`
  color: var(--color-black-light);
  border: 1px solid var(--color-white);
  border-radius: 4px;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 13px;
    line-height: 20px;
    color: transparent;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 13px;
    line-height: 20px;
    color: transparent;
  }

  ::placeholder {
    font-size: 13px;
    line-height: 20px;
    color: transparent;
  }

  &:focus,
  &:valid {
    border: 1px solid var(--color-blue);
  }

  &:focus + ${BLockIcon} {
    display: none;
  }
  &:valid + ${BLockIcon} {
    opacity: 0;

    ::-webkit-input-placeholder {
      /* Edge */

      color: var(--color-grey);
    }

    :-ms-input-placeholder {
      color: var(--color-grey);
    }

    ::placeholder {
      color: var(--color-grey);
    }
  }
`;
