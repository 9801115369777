import { ButtonPros } from 'core/types';
import { DefaultButton, IconButton } from 'components/buttons';
import React from 'react';
import styled from 'styled-components';

export const renderButtons = (buttons: ButtonPros[] = []) =>
  buttons?.map((btn) => {
    const Icon = btn?.icon;

    switch (btn.type) {
      case 'icon':
        return (
          <WrapperBtn key={btn.id}>
            <IconButton onClick={btn.onClick} style={btn.styled}>
              {!!btn?.icon && <Icon />}
            </IconButton>
          </WrapperBtn>
        );
      default:
        return (
          <WrapperBtn key={btn.id}>
            <DefaultButton onClick={btn.onClick} style={btn.styled}>
              {btn.title ?? ''}
            </DefaultButton>{' '}
          </WrapperBtn>
        );
    }
  });

const WrapperBtn = styled.div`
  &:not(:first-child) {
    margin-left: 8px;
  }
`;
