import styled, { css } from 'styled-components';

export const ContentPopUpList = styled.div<{ active: boolean; padding?: boolean }>`
  padding: ${({ padding }) => (padding ? 0 : '8px')};
  width: min-content;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  border: 0.5px solid #dfe1e4;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: var(--color-white);
  ${({ active }) =>
    active
      ? css`
          opacity: 1;
          z-index: 9999;
        `
      : css`
          opacity: 0;
          z-index: -1;
        `}
`;
export const ItemContentPopUpList = styled.div`
  padding: 8px 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background: var(--color-grey-light);
  }
`;
export const ItemContentPopUpListFilter = styled.div<{ active?: boolean }>`
  padding: 8px 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  ${({ active }) =>
    active
      ? css`
          background: var(--color-grey-light-active);
        `
      : css`
          background: var(--color-white);
        `}
`;
export const TitleFilter = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-blue);
  white-space: nowrap;
`;

export const TitleBlock = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #282a2f;
`;

export const BlockTabsContent = styled.div`
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Info = styled.div`
  padding: 50px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black-light);
`;

export const Space = styled.button`
  cursor: pointer;
  padding: 20px;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d7d7d7;
  border-radius: 8px;

  &:hover {
    background: #cbcbcb;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
