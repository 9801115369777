import React from 'react';
import styled from 'styled-components';

export const DefaultButton = styled.button`
  width: 100%;
  background: var(--color-blue);
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-white);
  border-radius: 4px;
  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
`;

export const IconButton = styled.button`
  background: var(--color-white);
  border: 1px solid var(--color-grey);
  border-radius: 4px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
`;

export const WhiteButton = styled.button`
  width: 100%;
  white-space: nowrap;
  background: var(--color-white);
  border: 1px solid #dfe1e4;
  border-radius: 4px;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
`;
