import React from 'react';
import styled, { css } from 'styled-components';

type ItemCardInfo = {
  active: boolean;
  time: string;
  info: string;
};

export const ItemCardInfo: React.FC<ItemCardInfo> = ({ time, info, active }) => {
  return (
    <Content active={active}>
      <Text>{time}</Text>
      <Text>{info}</Text>
    </Content>
  );
};

const Text = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-white);
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
const Content = styled.div<{ active?: boolean }>`
  padding: 12px 8px;
  border-radius: 4px;
  background: #c0a3ef;
  width: 100%;
  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0.5;
        `}
`;
