import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Navbar } from './features/navbar';
import { Routing } from './core/routes';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from './core/hooks';

export const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    if (['/auth/sign_in', '/auth/sign_up'].includes(pathname)) {
      setAuth(true);
    }
    return () => {
      setAuth(false);
    };
  }, [pathname]);

  return (
    <WrapperApp>
      {!auth && <Navbar />}
      <Content>
        <Routing />
      </Content>
    </WrapperApp>
  );
};

const WrapperApp = styled.div`
  min-height: 100vh;
  height: max-content;
  display: flex;
`;
const Content = styled.div`
  width: 100%;
  height: min-content;
  overflow-x: auto;
`;
