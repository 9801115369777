import createRequestRest from 'api/createRequestRest';
import { put } from 'redux-saga/effects';
import { workplaceSlice } from '../workplaceSlice';

function* prepareRequest(payload: any) {
  return payload?.payload?.data;
  yield;
}

function* prepareSuccess(response: any, payload: any) {
  yield put(workplaceSlice.actions.searchServiceSuccess(response));
  payload?.payload?.success?.();
}

function* prepareFailure(res: any) {
  yield;
}

export const searchService = () => {
  return createRequestRest({
    url: '/api/v1/service/search',
    prepareRequest,
    prepareSuccess,
    prepareFailure,
    action: workplaceSlice.actions.searchServiceRequest,
    method: 'get',
  });
};
