import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { IconChecked } from '../../icons';

type CheckboxProps = {
  disabled?: boolean;
  title?: string;
  active: boolean;
  setActive: () => void;
  height?: number;
  width?: number;
};

export const CheckboxService: React.FC<CheckboxProps> = (props) => {
  const { height = 20, width = 20 } = props;
  return (
    <WrapperContent onClick={!props?.disabled ? props.setActive : () => undefined}>
      <Content style={{ minHeight: height, minWidth: width }} disabled={props.disabled}>
        {props.active && <Icon />}
      </Content>
      {!!props.title && <Title>{props.title}</Title>}
    </WrapperContent>
  );
};
const Icon = styled(IconChecked)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  margin-left: 10px;
`;

const WrapperContent = styled.div`
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
`;

const Content = styled.div<{ disabled?: boolean }>`
  position: relative;
  border: ${({ disabled }) => (disabled ? '1px solid var(--color-grey)' : '1px solid #DFE1E4')};
  border-radius: 3px;
  user-select: none;
`;
