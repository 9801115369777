import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconFilter } from 'icons';
import { ContentPopUpList, TitleFilter } from '../style';
import { useMouseDown } from 'core/hooks';
import { activeMultiFilter, countActiveMultiFilter } from './utils';
import { Input } from '../input';
import { InputMack } from '../inputMask';
import { ItemSelectPros, SelectorStatic } from '../selectorStatic';
import { clearValueDate } from '../../core/utils';
import { MultiFilterContextPropsContextProvider, useMultiFilterContext } from './contextMultiFIlter';

const genderIds: Record<string, string | undefined> = {
  male: '0',
  female: '1',
  none: undefined,
};

const gardenData: ItemSelectPros[] = [
  { id: '0', value: 'Mужской', data: 'male' },
  { id: '1', value: 'Женский', data: 'female' },
];

export type TypeMultiFilter = 'INPUT' | 'DATE' | 'SELECTOR';

export type MultiFilterItemProps = {
  id: number;
  active: boolean;
  label: string;
  value: string | number;
  query: string;
  type: TypeMultiFilter;
};

export type MultiFilterDataProps = Record<string | number, MultiFilterItemProps>;

type FilterProps = {
  data: MultiFilterDataProps;
  onChange: (data: MultiFilterDataProps) => void;
  onClose?: () => void;
  state?: Record<string, any>;
};

export const MultiFilter: React.FC<FilterProps> = ({ data, onChange, onClose, state }) => {
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const [counts, setCounts] = useState<number>(0);

  const [showList, setShowList] = useState(false);

  useMouseDown(
    () => {
      showList && onClose?.(); //вызывается если нет showList
      setShowList(false);
    },
    listRef,
    inputRef,
  );

  const handleClickShow = () => {
    setShowList((prev) => !prev);
  };

  const handleClickInout = (value: any, id: string | number, data: MultiFilterDataProps) => {
    const nextData: MultiFilterDataProps = activeMultiFilter(value, id, data) as MultiFilterDataProps;
    onChange(nextData);
    setCounts(countActiveMultiFilter(nextData));
  };

  return (
    <Content active={showList}>
      <Header ref={inputRef} onClick={handleClickShow}>
        <WrapperIcon>
          <IconFilter />
        </WrapperIcon>
        <TitleFilter>Фильтр</TitleFilter>
        {!!counts && <CountFilter>{counts}</CountFilter>}
      </Header>
      <ContentPopUpList ref={listRef} active={showList}>
        {Object.values(data).map((item) => {
          switch (item.type) {
            case 'INPUT':
              return (
                <ItemMap key={item.id}>
                  <Input
                    placeholder={item.label}
                    value={item.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleClickInout(e.target.value, item.id, data)
                    }
                  />
                </ItemMap>
              );
            case 'DATE':
              return (
                <ItemMap key={item.id}>
                  <InputMack
                    mask={'99.99.9999'}
                    placeholder={item.label}
                    value={item.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = clearValueDate(e.target.value);
                      if (value.length === 8) {
                        handleClickInout(e.target.value, item.id, data);
                      } else handleClickInout(value, item.id, data);
                    }}
                  />
                </ItemMap>
              );
            case 'SELECTOR':
              return (
                <ItemMap key={item.id}>
                  <SelectorStatic
                    isClear
                    valueId={genderIds[item?.value || 'none']}
                    placeholder='Пол'
                    data={gardenData}
                    onChange={(e) => handleClickInout(e.data, item.id, data)}
                  />
                </ItemMap>
              );
            default:
              return <></>;
          }
        })}
      </ContentPopUpList>
    </Content>
  );
};

const ItemMap = styled.div`
  width: 200px;
  padding: 8px 10px;
`;
const CountFilter = styled.div`
  height: 18px;
  width: 18px;
  background: var(--color-blue);
  color: var(--color-white);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const Header = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 26px;
`;
const Content = styled.div<{ active: boolean }>`
  width: 100%;
  position: relative;
  padding: 8px 10px;
  border-radius: 4px;
  &:hover {
    background: #f0f3f9;
  }
  ${({ active }) =>
    active
      ? css`
          background: #f0f3f9;
        `
      : null}
`;
