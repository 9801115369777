import { all } from 'redux-saga/effects';
import { getAllService } from './getService';
import { postCreateService } from './postCreateService';
import { getCategoryService } from './getCategoryService';
import { getAllServiceId } from './getServiceId';
import { postUpdateService } from './postUpdateService';
import { deleteService } from './deleteService';
import { getCategoryId } from './getCategoryId';
import { createService } from './postCreateCategory';
import { getCategorySelectorService } from './getCategorySelector';
import { updateCategory } from './postUpdateCategory';
import { deleteCategory } from './deleteCategory';
import { postCopyService } from './postCopyService';
import { getSearchService } from './getSearchService';

export default function* serviceSaga() {
  yield all([
    getAllService(),
    postCreateService(),
    getCategoryService(),
    getAllServiceId(),
    postUpdateService(),
    deleteService(),
    getCategoryId(),
    createService(),
    getCategorySelectorService(),
    updateCategory(),
    deleteCategory(),
    postCopyService(),
    getSearchService(),
  ]);
}
