import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PlusInput } from '../../../../../features/plusInput';
import { SearchSelectorDefault } from '../../../../../features/searchSelectorDefault';
import { useSearch } from '../../../hooks/useSearch';
import { requestsSearch } from '../../../../../api/search';
import { IconClose } from '../../../../../icons';
import { useAppSelector } from '../../../../../core/hooks';
import { kanbanSelector } from '../../../kanbanSlice';

type SpecialistItemProps = {
  getActiveData?: (data?: Record<string, any> | null) => void;
};

export const SpecialistItem: React.FC<SpecialistItemProps> = ({ getActiveData }) => {
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [loading, setLoading] = useState(true);

  const [searchServiceValue, setSearchServiceValue] = useState('');

  const { handledOnChangeSearch, handleClickItem, activeItem, clearItem, requestData, setActiveItem } = useSearch({
    setLoading: setLoading,
    setSearch: setSearchServiceValue,
    request: (search?: string) =>
      requestsSearch
        .get_specialist({
          title: search || null,
        })
        .then((res: any) => {
          if (res?.data) {
            setLoading(false);
            if (res?.data?.data) {
              return res?.data?.data.map((item: Record<string, any>) => ({
                id: item.id,
                name: [item?.name, item?.surname, item.patronymic].filter(Boolean).join(' '),
              }));
            }
          }
        }),
  });

  useEffect(() => {
    getActiveData?.(activeItem);
  }, [activeItem]);

  useEffect(() => {
    if (activeCard) {
      const specialist = activeCard?.specialist;
      specialist &&
        setActiveItem({
          id: specialist?.id || specialist?.workplace_id,
          name: [specialist?.name || '', specialist?.surname || '', specialist.patronymic || '']
            .filter(Boolean)
            .join(' '),
        });
    }
  }, [activeCard]);

  return (
    <Content>
      {activeItem ? (
        <WrapperTitle>
          <Title>{activeItem.name}</Title>
          <WrapperIcon onClick={clearItem}>
            <IconClose />
          </WrapperIcon>
        </WrapperTitle>
      ) : (
        <>
          <PlusInput
            component={
              <SearchSelectorDefault
                onFocus={() => setLoading(true)}
                onBlur={() => setLoading(false)}
                value={searchServiceValue || ''}
                loading={loading}
                onChange={handledOnChangeSearch}
                data={requestData}
                handleClickItem={handleClickItem}
                isClear
                onClear={clearItem}
              />
            }
          />
        </>
      )}
    </Content>
  );
};

const Content = styled.div``;
const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black-light);
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  width: 220px;
`;
const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  svg {
    path {
      stroke: #d23333;
    }
  }
`;
