// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import ReactInputMask from 'react-input-mask';

const CustomInputMask = React.forwardRef<HTMLInputElement>((props, ref) => {
  return <ReactInputMask mask='99:99' inputRef={ref} {...props} />;
});

CustomInputMask.displayName = 'CustomInputMask';
export default CustomInputMask;
