import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

type TelegramStateProps = {
  telegramSettings: any[];
};

const initialState: TelegramStateProps = {
  telegramSettings: [],
};

export const recordPrioritySlice = createSlice({
  name: 'recordPriority',
  initialState,
  reducers: {
    //получение настроек бота
    getTelegramSettingsRequest: (state: TelegramStateProps) => {
      //
    },
    //успех получения
    getTelegramSettingsSuccess: (state: TelegramStateProps, action) => {
      state.telegramSettings = action?.payload;
    },
    //обеовление настроек бота
    postUpdateTelegramSettingsRequest: (state: TelegramStateProps, action) => {
      state.telegramSettings = action.payload;
    },
    //успех обновления
    postUpdateTelegramSettingsSuccess: (state: TelegramStateProps, action) => {
      state.telegramSettings = action?.payload;
    },
  }, 
});

export const telegramSettingsServiceSelector = (s: RootState) => s.recordPriority.telegramSettings;
