import React, { useMemo, useState } from 'react';
import { TabsContextProvider, useTabsContext } from 'pages/kanban/tabsContent/context';
import { ModalAnt } from 'components/modalAnt';
import { ItemTabs, Tabs } from 'components/tabs';
import { DefaultButton, IconButton } from 'components/buttons';
import { PopUpAnt } from 'components/popupAnt';
import styled from 'styled-components';
import { EntryContent } from 'pages/kanban/tabsContent/entryContent';
import { kanbanSelector, kanbanSlice } from 'pages/kanban/kanbanSlice';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { v4 as idV4 } from 'uuid';
import { IconTrash } from 'icons';

type ModalChangeCardKanbanProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  updateData: () => void;
};

const ChangeCardKanban: React.FC<ModalChangeCardKanbanProps> = ({ showModal, setShowModal, updateData }) => {
  const dispatch = useAppDispatch();
  const activeCard = useAppSelector(kanbanSelector.activeCard);
  const [showPopUp, setShowPopUp] = useState<'save' | 'delete' | null>();
  const { state: stateTabs, setState: setStateTabs } = useTabsContext();

  const item = useMemo(() => {
    const data = activeCard?.status_name;
    return {
      id: data?.id,
      name: data?.name,
      sort: data?.sort,
      prefix: idV4(),
    };
  }, [activeCard]);

  const tabs: ItemTabs[] = [
    {
      label: 'Запись',
      content: <EntryContent data={item} />,
    },
    // {
    //   label: 'Заказ',
    //   content: <OrderContent />,
    // },
  ];

  const handledClickClose = () => {
    setShowModal(false);
    setShowPopUp(null);
    dispatch(kanbanSlice.actions.resetActiveCard());
    dispatch(kanbanSlice.actions.resetActiveCardDefault());
    setStateTabs?.((prev) => ({
      ...prev,
      record: {
        ...prev?.record,
        data: null,
      },
    }));
    updateData();
  };

  const handledClickSave = () => {
    //
    if (stateTabs?.record?.onIsValid?.()) {
      dispatch(
        kanbanSlice.actions.postUpdateCardTodoListRequest({
          payload: {
            data: {
              client_id: stateTabs?.record?.data?.client_id,
              status_id: stateTabs?.record?.data?.status_id,
              ...stateTabs?.record?.data?.services?.[0],
            },
            success: (res: Record<string, any>) => {
              if (res.message?.type !== 'error') {
                dispatch(kanbanSlice.actions.getAllTodoListRequest());
                setShowPopUp(null);
              }
            },
          },
        }),
      );
    } else {
      stateTabs?.record?.checkValid?.();
    }
  };

  const handledClickAdd = () => {
    if (stateTabs?.record?.onIsValid?.()) {
      dispatch(
        kanbanSlice.actions.postCreateTodoListRequest({
          payload: {
            data: stateTabs?.record?.data,
            success: (res: Record<string, any>) => {
              if (res.message?.type !== 'error') {
                handledClickClose();
                dispatch(kanbanSlice.actions.getAllTodoListRequest());
              }
            },
          },
        }),
      );
    } else {
      stateTabs?.record?.checkValid?.();
    }
  };

  const handedClickRemove = () => {
    dispatch(
      kanbanSlice.actions.postDeleteCardTodoListRequest({
        payload: {
          data: { id: activeCard?.id },
          success: () => {
            dispatch(kanbanSlice.actions.getAllTodoListRequest());
            handledClickClose();
          },
        },
      }),
    );
  };

  const contentPopUp = {
    save: {
      title: 'Сохранить изменения?',
      onClick: () => handledClickSave(),
    },
    delete: {
      title: 'Удалить карточку?',
      onClick: () => handedClickRemove(),
    },
  };

  return (
    <>
      {showModal && (
        <ModalAnt show={true} onClose={handledClickClose}>
          <Tabs data={tabs} />
          <FooterBtn>
            <BLockBtn>
              {activeCard ? (
                <>
                  <WrapperButton>
                    <DefaultButton onClick={() => setShowPopUp('save')}>Сохранить</DefaultButton>
                  </WrapperButton>
                </>
              ) : (
                <>
                  <WrapperButton>
                    <DefaultButton onClick={handledClickAdd}>Добавить прием</DefaultButton>
                  </WrapperButton>
                </>
              )}
            </BLockBtn>
            {activeCard?.id && (
              <WrapperIconButton>
                <IconButton onClick={() => setShowPopUp('delete')}>
                  <IconTrash />
                </IconButton>
              </WrapperIconButton>
            )}
          </FooterBtn>
        </ModalAnt>
      )}
      {!!showPopUp && (
        <PopUpAnt
          visible
          title={contentPopUp[showPopUp].title}
          onClick={contentPopUp[showPopUp].onClick}
          onCancel={() => setShowPopUp(null)}
        />
      )}
    </>
  );
};

export const ModalChangeCardKanban: React.FC<ModalChangeCardKanbanProps> = (props) => {
  return (
    <TabsContextProvider>
      <ChangeCardKanban {...props} />
    </TabsContextProvider>
  );
};

const WrapperIconButton = styled.div`
  button {
    width: 40px;
    height: 40px;
    color: #6b6f76;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
`;
const WrapperButton = styled.div`
  button {
    height: 40px;
  }
  &:not(:last-child) {
    margin-right: 18px;
  }
`;
const BLockBtn = styled.div`
  display: flex;
  align-items: center;
`;
const FooterBtn = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const WrapperColumn = styled.div`
  height: 100%;
`;
const WrapperItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
const Content = styled.div`
  background: #f4f5f8;
  border-radius: 8px;
  padding: 8px;
  height: 100%;
  width: 100%;
`;
