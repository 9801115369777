import { ItemNavBar } from './type';
/**
 * Открытие и закрытие меню
 * @param data
 */
export const toggleShow = (data: ItemNavBar[]) => (id: number) =>
  data.map((i) => (i.id === id ? { ...i, show: !i.show } : i));

/**
 * Устанавливаю активные пункты меню
 * @param data
 */
export const setActive =
  (data: ItemNavBar[]) =>
  (path: string): ItemNavBar[] =>
    data.map((item) => {
      if (item.child?.length) {
        return { ...item, child: setActive(item.child)(path) };
      } else {
        if (item.path === path) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      }
    });
