import React from 'react';
import styled, { css } from 'styled-components';
import { IconDragPoints } from 'icons';
import { Checkbox } from 'components/checkbox';
import { useDrag, useDrop } from 'react-dnd';
import { ItemRowTypes, ItemTypes } from '../types';
import { useCombinedRefs } from '../useCombinedRefus';

const noop = () => undefined;
type ItemRowProps = {
  data: ItemRowTypes;
  indentationLeft?: number;
};
export const ItemRow: React.FC<ItemRowProps> = ({ data, indentationLeft = 0 }) => {
  const { name, require, active, number } = data;

  //берем элемент
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ROW_SETTINGS,
    item: {
      rowId: data.id,
    },
    collect: (monitor) => {
      // console.log({ monitor });
      return {
        isDragging: !!monitor.isDragging(),
      };
    },
  }));
  //бросаем элемент
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ROW_SETTINGS,
    drop(data: ItemRowTypes) {
      console.log({ data });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Content ref={useCombinedRefs(drag, drop)} indentationLeft={indentationLeft}>
      <WrapperIcon>
        <IconDragPoints />
      </WrapperIcon>
      <Input value={number} />
      <Title>{name}</Title>
      <Checkbox active={require} setActive={noop} />
      <Checkbox active={active} setActive={noop} />
    </Content>
  );
};
const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 2px;
`;
const Title = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: var(--color-black);
`;
const Content = styled.div<{ indentationLeft?: number }>`
  display: grid;
  grid-template-columns: 50px 70px 1fr 180px 100px;
  grid-template-rows: 48px;
  align-items: center;
  padding: 5px 0;
  border-top: 1px solid #eff1f4;
  border-bottom: 1px solid #eff1f4;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  ${({ indentationLeft }) => {
    return css`
      padding-left: ${indentationLeft}px;
    `;
  }}
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  min-width: 20px;
  width: 20px;
  padding: 5px;
`;
