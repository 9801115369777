import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IconLogo } from 'icons';
import { useAppDispatch } from 'core/hooks';
import { authSlice } from './authSlice';
import { useNavigate } from 'react-router-dom';

type LayoutAuthProps = {
  children?: React.ReactNode;
};

export const LayoutAuth: React.FC<LayoutAuthProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authSlice.actions.getUserRequest());
  }, []);

  const auth = localStorage.getItem('auth');

  useEffect(() => {
    if (auth) {
      navigate('/');
    }
  }, [auth]);
  return (
    <Content>
      <WrapperLogo>
        <IconLogo />
      </WrapperLogo>
      {children}
      <Footer>
        <BlockInfo>
          <Link href='tel:88007776655'>8 800 777–66–55</Link>
          <Link href='mailto:info@anvilhook.ru'>info@anvilhook.ru</Link>
          <LinkChat>Чат поддержки</LinkChat>
        </BlockInfo>
        <Company>ООО «Энвилхук брендинг»</Company>
      </Footer>
    </Content>
  );
};

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 40px;
  top: 30px;
  svg {
    path {
      fill: #6932b1;
    }
  }
`;
const Content = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`;

const Footer = styled.div`
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const BlockInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  margin-bottom: 8px;
`;
const Link = styled.a`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #575b61;
`;
const LinkChat = styled.div`
  cursor: pointer;

  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  &:hover {
    text-decoration: underline;
  }
`;
const Company = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #575b61;
`;
