import moment from 'moment';

const reductionStr = (strOne: string, strTwo: string) => {
  return [strOne.split('')?.[0]?.toUpperCase(), strTwo.split('')?.[0]?.toUpperCase()].filter(Boolean).join('');
};

const reductionName = (name?: string, surName?: string, patronymic?: string) => {
  return [
    `${name || ''} ` || '',
    surName ? ` ${surName.split('')?.[0]?.toUpperCase()}.` : '',
    patronymic ? `${patronymic.split('')?.[0]?.toUpperCase()}.` : '',
  ]
    .filter(Boolean)
    .join('');
};

export const transformClient = (data: Record<string, any>) => {
  return {
    id: data?.id || data?.client_id || '',
    reduction: reductionStr(data?.name || '', data?.surname || ''),
    fullName: [data?.surname, data?.name, data?.patronymic].filter(Boolean).join(' '),
    name: reductionName(data?.surname || '', data?.name || '', data?.patronymic || ''),
    phone: data?.phone || '',
    birthday_at: moment(data?.birthday_at).format('DD.MM.YYYY'),
  };
};

export const transformSearchClients = (data: Record<string, any>[]) => {
  return data.map(transformClient);
};
