import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconCloseSmall, IconPlusLight } from 'icons';
import { Input } from 'components/input';

type PlusInputProps = {
  component?: React.ReactNode;
  maxValue?: number;
};

export const PlusInput: React.FC<PlusInputProps> = ({ component = <Input />, maxValue = 1 }) => {
  const [mass, setMass] = useState<number[]>([]);
  const [count, setCount] = useState(0);

  const handledClickPlus = () => {
    setCount((prev) => prev + 1);
    setMass((prev) => [...prev, count + 1]);
  };

  const handledClickRemove = () => {
    setCount((prev) => prev - 1);
    const nextMass = [...mass];
    nextMass.pop();
    setMass(nextMass);
  };

  return (
    <Content>
      {!!count &&
        mass.map((item) => {
          return (
            <WrapperInput key={item}>
              {component}

              <WrapperIconClose onClick={handledClickRemove}>
                <IconCloseSmall />
              </WrapperIconClose>
            </WrapperInput>
          );
        })}
      {count !== maxValue && (
        <WrapperIcon onClick={handledClickPlus}>
          <IconPlusLight />
        </WrapperIcon>
      )}
    </Content>
  );
};
const WrapperInput = styled.div`
  margin-right: 8px;
  position: relative;
`;
const WrapperIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      stroke: #6471e0;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #c0c0c0;
      }
    }
  }
`;

const WrapperIconClose = styled(WrapperIcon)`
  position: absolute;
  top: -2px;
  right: -2px;
  background: #fff;
  height: 15px;
  width: 15px;
  padding: 2px;
  border-radius: 50%;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
