import React, { InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type TextareaProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  valid?: boolean;
};

export const Textarea: React.FC<TextareaProps> = (p) => {
  const { valid = true } = p;
  return (
    <WrapperContent>
      <Content valid={!!valid} required {...p} />
      <Placeholder valid={!!valid}>{p.placeholder}</Placeholder>
    </WrapperContent>
  );
};
const WrapperContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Placeholder = styled.span<{ valid: boolean }>`
  color: var(--color-black-grey);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 16px;
  transform: translateY(50%);
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.33s ease;

  ${({ valid }) => {
    if (valid) {
      return css`
        color: var(--color-black-grey);
      `;
    } else {
      return css`
        color: var(--color-red);
      `;
    }
  }};
`;
const Content = styled.textarea<{ valid: boolean }>`
  font-family: 'Inter', sans-serif;
  padding: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--color-black);
  min-height: 80px;
  border: 1px solid #dfe1e4;
  border-radius: 8px;
  outline: none;
  width: 100%;
  height: 100%;
  resize: none;
  &::placeholder {
    color: #ffffff00;
  }
  &:focus + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
  }
  &:valid + ${Placeholder} {
    transform: translateY(0);
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    top: -6px;
    background: var(--color-white);
    padding: 0 5px;
  }

  ${({ valid }) => {
    if (valid) {
      return css`
        border: 1px solid #dfe1e4;
      `;
    } else {
      return css`
        border: 1px solid var(--color-red);
      `;
    }
  }};
`;
